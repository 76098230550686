export const getLinkBindings = (link) => {
  const bindings = {
    target: link?.value?.target ? link?.value?.target : '_self',
    alt: link?.value?.alt,
  };

  if (link?.value?.linktype === 'internal') bindings.to = link?.value?.href;
  else bindings.href = link?.value?.href;
  return bindings;
};

export const getLinkTag = (linktype) => {
  if (linktype === 'internal') return 'router-link';
  return 'a';
};
