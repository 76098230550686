<template>
  <template v-if="bookingOptionsLength > 1">
    <div
      class="booking-menu"
      :class="[{ 'booking-menu--active': menuActive }]"
      data-booking-block
      @mouseover="mouseOver"
      @mouseleave="closeMenuWithTimeout"
    >
      <button
        class="btn btn--secondary booking-menu__dropdownButton"
        :aria-expanded="menuActive"
        :tabindex="ctaButtonTabIndex"
        @click="handleKeyDown"
        @keydown.enter.space.prevent="handleKeyDown"
        @keydown.tab.shift.exact="closeMenu"
      >
        {{ t('book-cta-text-short') }}
        <i class="icon icon-Down" aria-hidden="true"></i>
      </button>
      <div class="booking-menu__subMenu">
        <base-button
          v-for="(option, name) in bookingOptions"
          :key="option.key"
          :class="`booking-menu__item booking-menu__item--${option.key} ${option.icon} no-underline`"
          data-booking-block
          variant="secondary"
          :type="name === CTA_BOOK_TABLE ? 'link' : null"
          :link="name === CTA_BOOK_TABLE ? option.data : null"
          :label="t(option.labelLong)"
          :aria-label="t(option.labelLong)"
          :data-cta-text-short="t(option.labelShort)"
          :data-cta-text-long="t(option.labelLong)"
          @click="onItemClick(name, t(option.labelLong), true)"
        />
      </div>
    </div>
  </template>
  <template v-else>
    <base-button
      v-for="(option, name) in bookingOptions"
      :key="option.key"
      class="main-header__button"
      data-booking-block
      variant="secondary"
      :type="name === CTA_BOOK_TABLE ? 'link' : null"
      :tabindex="ctaButtonTabIndex"
      :link="name === CTA_BOOK_TABLE ? option.data : null"
      :label="isDesktop ? t(option.labelLong) : t(option.labelShort)"
      :aria-label="isDesktop ? t(option.labelLong) : t(option.labelShort)"
      :data-cta-text-short="t(option.labelShort)"
      :data-cta-text-long="t(option.labelLong)"
      :data-test-id="`${option.key}-single-button`"
      @click="onItemClick(name, isDesktop ? t(option.labelLong) : t(option.labelShort), false)"
    />
  </template>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useAppStore } from '../../../stores/appStore';
import BaseButton from '../../2_elements/BaseButton/BaseButton.vue';
import useMedia from '../../../utils/useMedia';

const CTA_BOOK_TABLE = 'ctaBookTable';
const CTA_BOOK_ROOM = 'ctaBookRoom';

const appStore = useAppStore();
const { t } = useI18n();
const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

const menuActive = ref(false);
let openingTimeout = null;
let closingTimeout = null;

const emit = defineEmits(['booking-menu-open']);

const props = defineProps({
  bookingOptions: {
    type: Object,
    default: () => ({}),
  },
  bookingOptionsLength: {
    type: Number,
  },
  handleBookingClick: {
    type: Function,
  },
  ctaButtonTabIndex: {
    type: Number,
  },
});

const mouseOver = () => {
  if (openingTimeout) return;
  openMenu();
};

const handleKeyDown = () => {
  if (openingTimeout) return;
  menuActive.value ? closeMenu() : openMenu();
};

const openMenu = () => {
  if (closingTimeout) clearTimeout(closingTimeout);
  menuActive.value = true;
  setOpeningTimeout();
  emit('booking-menu-open');
};

const closeMenu = () => {
  menuActive.value = false;
};

const setOpeningTimeout = () => {
  if (openingTimeout) resetOpeningTimeout();
  openingTimeout = setTimeout(resetOpeningTimeout, 50);
};

const resetOpeningTimeout = () => {
  clearTimeout(openingTimeout);
  openingTimeout = null;
};

const closeMenuWithTimeout = () => {
  closingTimeout = setTimeout(closeMenu, 300);
};

const onItemClick = (name, label, isSubmenuItem) => {
  if (name === CTA_BOOK_ROOM)
    appStore.setGuestlineActiveUrl(props.bookingOptions[CTA_BOOK_ROOM].guestlineUrl);
  props.handleBookingClick(label);
  if (isSubmenuItem) closeMenu();
};

defineExpose({ closeMenu });
</script>

<style lang="scss">
@import './scss/BookingMenu.scss';
</style>
