<template>
  <template v-if="appStore.guestlineActiveUrl">
    <Teleport to="body">
      <iframe-bar
        :src="appStore.guestlineActiveUrl"
        :is-open="isOpen"
        :header="t('book-room-cta-long')"
        @on-close="closeIframeBar"
        @on-close-animation-end="clearGuestlineActiveUrl"
      />
    </Teleport>
  </template>
</template>

<script setup>
import { ref, watch, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import IframeBar from '../IframeBar/IframeBar.vue';
import { useAppStore } from '../../../stores/appStore';
import { useGuestlineListener } from '../../../composables/useGuestlineListener';

const { t } = useI18n();
const appStore = useAppStore();
const isOpen = ref(false);

const jssStore = inject('jssStore');
const guestlineUrl = jssStore?.sitecoreContext()?.guestlineUrl || null;
if (guestlineUrl) useGuestlineListener(guestlineUrl);

const openIframeBar = () => {
  setTimeout(() => (isOpen.value = true), 0);
};

const closeIframeBar = () => {
  isOpen.value = false;
};

const clearGuestlineActiveUrl = () => {
  appStore.setGuestlineActiveUrl(null);
};

watch(
  () => appStore.guestlineActiveUrl,
  () => {
    if (appStore.guestlineActiveUrl) openIframeBar();
  }
);
</script>
