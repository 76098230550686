import { inject, computed } from 'vue';
import { isServer } from '@sitecore-jss/sitecore-jss';

export const DEFAULT_DATA_LAYER = 'DEFAULT_DATA_LAYER';
export const ECOMMERCE_DATA_LAYER_ITEM = 'ECOMMERCE_DATA_LAYER_ITEM';
export const VENUE_FINDER_DATA_LAYER = 'VENUE_FINDER_DATA_LAYER';
export const BOOKING_DATA_LAYER_ITEM = 'BOOKING_DATA_LAYER_ITEM';

export const useDataLayerDefaultProps = (dataLayerType) => {
  const jssStore = inject('jssStore');
  const sitecoreContext = jssStore?.sitecoreContext() || {};
  const venueName = sitecoreContext.venueName;
  const venueId = sitecoreContext.venueId;
  const venueOrigin = sitecoreContext.venueOrigin ?? '';
  const itemPath = sitecoreContext.itemPath ?? '';
  const brandName = sitecoreContext.brandName;
  const segment = sitecoreContext.segment;
  const path = sitecoreContext.siteVirtualFolder?.split('/');

  const fullUrl = computed(() =>
    !isServer() ? window.location.href.split('?')[0] : venueOrigin + itemPath
  );

  const dataLayerDefaultProps = {
    [DEFAULT_DATA_LAYER]: {
      defaultGlobalProps: {
        event: 'basic_event',
        pub: venueName && venueId ? `${venueName}-${venueId}` : null,
        link_page_path: itemPath,
        link_domain: venueOrigin,
      },
    },
    [ECOMMERCE_DATA_LAYER_ITEM]: {
      defaultItemProps: {
        location_id: fullUrl.value,
      },
    },
    [VENUE_FINDER_DATA_LAYER]: {
      defaultGlobalProps: {
        event: 'basic_event',
        link_page_path: itemPath,
        link_domain: venueOrigin,
      },
    },
    [BOOKING_DATA_LAYER_ITEM]: {
      defaultGlobalProps: {
        userId: '',
        _userProject: 'BookingApp',
      },
      defaultEcommerceProps: {
        coupon: '',
        _currency: 'GBP',
        payment_type: '',
        tax: 0,
        value: 0,
      },
      defaultItemProps: {
        additional_requirements: false,
        _affiliation: venueName,
        _brand: brandName,
        _currency: 'GBP',
        coupon: '',
        disabled_access: null,
        discount: 0,
        highchair_requested: 0,
        index: 0,
        _item_brand: segment,
        item_id: venueId,
        item_list_name: 'no_search_performed',
        item_variant: null,
        item_category2: path?.[1],
        item_category3: path?.[2],
        item_category4: path?.[3],
        price: 0,
        _pub_name: venueName,
        venue_id: venueId,
        quantity: 1,
      },
    },
  };

  return dataLayerDefaultProps?.[dataLayerType] || {};
};
