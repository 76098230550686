<template>
  <component
    :is="componentType"
    :class="classes"
    :data-test-id="dataTestId"
    :tabindex="tabIndex"
    v-bind="componentBindings"
    :text="text || field.value?.text"
    @click="onClick"
  >
    {{ text || field.value?.text }}
  </component>
</template>

<script setup>
import { computed } from 'vue';
import { getLinkTag } from '../../../utils/linkTransformer';
import { useAppStore } from '../../../stores/appStore';

const ANCHOR = 'anchor';
const INTERNAL_LINK = 'internal';
const GUESTLINE = 'guestline';

const emit = defineEmits(['click']);

const props = defineProps({
  field: {
    type: Object,
    default: () => ({}),
  },
  href: {
    type: String,
    default: () => null,
  },
  text: {
    type: String,
    default: () => null,
  },
  classes: {
    type: String,
    default: () => null,
  },
  dataTestId: {
    type: String,
    default: () => null,
  },
  tabIndex: {
    type: Number,
    default: () => null,
  },
});

const componentType = computed(() => getLinkTag(props.field?.value?.linktype));

const isGuestlineLink = computed(() => props.field?.value?.linktype === GUESTLINE);

const appStore = isGuestlineLink.value ? useAppStore() : null;

const onClick = () => {
  if (isGuestlineLink.value && props.field?.value?.href)
    appStore.setGuestlineActiveUrl(props.field.value.href);
  emit('click');
};

const componentBindings = computed(() => {
  const bindings = {};
  bindings.target = props.field?.value?.target || null;
  bindings.rel = props.field?.value?.target == '_blank' ? 'noopener noreferrer' : null;
  bindings.alt = props.field?.value?.alt || null;

  let href = props.href || props.field?.value?.href;
  if (href && props.field?.value?.anchor && props.field?.value?.linktype !== ANCHOR)
    href += `#${props.field.value.anchor}`;

  if (props.field?.value?.linktype === INTERNAL_LINK) bindings.to = href;
  else if (isGuestlineLink.value) {
    bindings.role = 'button';
    bindings.tabindex = 0;
  } else bindings.href = href;
  return bindings;
});
</script>
